<div class="row justify-content-center g-0">
  <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
    <div *ngIf="alert" class="alert alert-{{alert.type}}">{{alert.message}}</div>
    <h2 *ngIf="processing" class="text-center">
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    </h2>

    <form *ngIf="!processing" name="form" (ngSubmit)="false" [formGroup]="typedForm" novalidate>    
      <div class="d-flex justify-content-between">
        <h1>pools</h1>
        <select class="form-control ms-1 pointer input-sm seasonlist flex-shrink-1" formControlName="season" (change)="updatePools()">
          <option *ngFor="let selectableSeason of selectableSeasons" [ngValue]="selectableSeason">{{selectableSeason.getName()}}</option>
        </select>
      </div>
    </form>
         
    <h2 *ngIf="searching" class="text-center">
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    </h2>
    <div *ngIf="!searching" class="mt-3">
      <table class="table mt-2">
        <tbody>
          <tr class="pointer" (click)="linkToPool(poolShell)" *ngFor="let poolShell of poolShells">
            <td>{{poolShell.name}}</td>
            <td>{{poolShell.nrOfUsers}}</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>