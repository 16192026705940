<footer *ngIf="showFooter" class="footer pt-0 mt-3 bg-primary text-light">
  <div class="container">
    <div class="row">
      <div class="col-sm-4 mt-3">
        <h5>contact</h5>
        <ul class="list-unstyled">
          <li>
            <a href="mailto:info@superelf-eredivisie.nl" rel="noopener">
              <fa-icon [icon]="[ 'fas', 'envelope'] "></fa-icon> info@superelf-eredivisie.nl
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sm-4 mt-3">
        <h5>projecten</h5>
        <ul class="list-unstyled">
          <li>
            <a target="_blank" rel="noopener" href="https://www.voetbaloog.nl/" tabindex="-1">voetbaloog</a>
          </li>
          <li>
            <a target="_blank" rel="noopener" href="https://fctoernooi.nl/" tabindex="-1">fctoernooi</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-4 mt-3">
        <h5>links</h5>
        <ul class="list-unstyled">
          <li>
            <a target="_blank" rel="license noopener" href="https://creativecommons.org/licenses/by-nc-sa/3.0/"
              tabindex="-1">cc
              by-nc-sa
              <span> 3.0 licentie</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-none d-sm-block text-center pt-4">
      <fa-icon [icon]=" [ 'fas', 'copyright'] "></fa-icon> SuperElf
    </div>
  </div>
</footer>