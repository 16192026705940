<div class="row justify-content-center g-0">
  <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
    <div *ngIf="alert" class="alert alert-{{alert.type}}">{{alert.message}}</div>
    <h2 *ngIf="processingMyShells" class="text-center">
      <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    </h2>

    <div *ngIf="!processingMyShells" class="mt-3">
      <!-- header -->
      <div *ngIf="(canCreateAndJoin || canAssemble) && myShells.length > 0" class="mt-3 d-flex justify-content-between">
        <h2 class="text-center">mijn pools</h2>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-primary" (click)="linkToNew()">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> pool organiseren
          </button>
        </div>
      </div>
      <div *ngIf="(canCreateAndJoin || canAssemble) && myShells.length === 0" class="mt-3">
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-primary" (click)="linkToNew()">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> pool organiseren
          </button>
        </div>
      </div>
      <div *ngIf="!(canCreateAndJoin || canAssemble) && myShells.length > 0" class="mt-3">
        <h2 class="text-center">mijn pools</h2>
      </div>

      <!-- myShells -->
      <table *ngIf="myShells.length > 0" class="table mt-2">
        <tbody>
          <tr class="pointer" (click)="linkToPool(shell)" *ngFor="let shell of myShells">
            <td>{{shell.name}}</td>
            <td>{{shell.seasonName}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="text-center mt-5">

      <button type="button" class="btn btn-secondary" [routerLink]="['/pool/worldcup', 0, 0]">
        wereld beker
      </button>
    </div> -->
    <div class="text-center mt-5">

      <button type="button" class="btn btn-secondary" [routerLink]="['/pools']">
        alle pools
      </button>
    </div>
    <div class="text-center mt-5">
      <a href="https://old.superelf-eredivisie.nl/" target="_blank" type="button" class="btn btn-info">
        naar oude superelf
      </a>
    </div>  
    <!-- <div class="text-center" style="margin-top: 100px;">
      <div class="alert alert-warning" role="alert">
        prijzenkast volgt...
      </div>
    </div>   -->
</div>