<nav class="navbar navbar-dark bg-primary py-1 ps-1 text-light">
  <div>
    <a class="navbar-brand text-light" tabindex="-1" [routerLink]="['/']">
      {{nameInfo ? 'S11' : 'SuperElf'}}
    </a>
  </div>

  <div *ngIf="nameInfo">
    <h1 class="h3 text-truncate me-1 d-flex mb-0 pointer" (click)="navigateToPoolHome(nameInfo.id)">
      <span class="me-1">{{nameInfo.name}}</span>

      <small class="d-flex flex-column p-0" style="font-size: 11px;">
        <span>{{getSeasonStart(nameInfo.start)}}</span>
        <span>{{getSeasonEnd(nameInfo.start)}}</span>
      </small>

    </h1>
  </div>

  <button *ngIf="!authService.isLoggedIn()" routerLink="/user/login" routerLinkActive="active" class="btn text-light"
    role="button" aria-label="inloggen " tabindex="-1">
    <fa-icon [icon]=" [ 'fas', 'sign-in-alt'] "></fa-icon>
    <span class="ms-1">inloggen</span>
  </button>
  <button *ngIf="authService.isLoggedIn()" [routerLink]="['/user/profile', authService.getUser()?.getId()]"
    class="btn text-light border-0 p-0 pt-1" role="button" tabindex="-1" aria-label="account">
    <fa-icon [icon]="[ 'fas', 'user-circle']" size="3x"></fa-icon>
  </button>

  <!-- <button class="navbar-toggler navbar-toggler-right pointer" type="button" data-toggle="collapse
    " data-target="#navbarResponsive "
    aria-controls="navbarResponsive " aria-label="Toggle navigation " (click)="navbarCollapsed=! navbarCollapsed
    " [attr.aria-expanded]="!navbarCollapsed ">
    <span class="navbar-toggler-icon "></span>
  </button>
  <div class="collapse navbar-collapse " [ngbCollapse]="navbarCollapsed " id="navbarResponsive ">
    <ul class="navbar-nav ">
      <li *ngIf="isLoggedIn() " class="nav-item ">
        <a class="nav-link " routerLink="/admin " routerLinkActive="active " (click)="navbarCollapsed=! navbarCollapsed ">admin</a>
      </li>
    </ul>

    <ul class="nav navbar-nav ms-auto ">
      <li *ngIf="!isLoggedIn() " class="nav-item ">
        <a class="nav-link" routerLink="/user/login " routerLinkActive="active " (click)="navbarCollapsed=!navbarCollapsed ">inloggen</a>
      </li>
      <li *ngIf="isLoggedIn() " class="nav-item ">
        <a class="nav-link" routerLink="/user/logout " routerLinkActive="active " (click)="navbarCollapsed=!navbarCollapsed ">uitloggen</a>
      </li>
    </ul>
  </div> -->
</nav>